import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../redux/actions";
import { useMergeState } from "../../utils/helpers";
import $ from "jquery";
import ICEHeader from "../../layouts/ICEHeader";
import Footer from "../../layouts/Footer";
import { enquireScreen } from "enquire-js";
import directorpic from  "../../assets/images/absu_director.png";
import vcpic from  "../../assets/images/absu_vc.jpeg";
import senatepic from  "../../assets/images/absu_senate.jpg";
import { PulseSpinner } from "react-spinners-kit";


let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const AboutICE = (props) => {
  const [state, setState] = useMergeState({
    featured: [],
    attendants: [],
    shopSchedule: [],
    newsOpen: false,
    newsTitle: "",
    newsValue: "",
    myPosts: [],
  });


  useEffect(() => {
    
    setTimeout(() => {
      $("#preloader").fadeOut();
    }, 2000);
    }, []);

  require("antd/dist/antd.css");

  return (
    <>
    <div id="preloader">
        <div id="status">
          <center>
            <PulseSpinner
              color="white"
              backColor="#FFF"
              frontColor="#FFF"
              size={45}
            />
          </center>
        </div>
    </div>
      <div className="min-vh-100">
        <ICEHeader />
        <div style={{paddingTop:"6em"}}>
            <img className="" src={senatepic} alt="" style={isMobile ? {width: "100%", height: "150px" } : {width: "100%", height: "270px"}}/>
        </div>
        <div className="container pt-4 bg-sky px-4"> 
            <div className="pt-4">
                <h1 style={{fontWeight:"800"}}>MESSAGE FROM THE DIRECTOR</h1>
                <div className="d-block d-lg-flex">
                    <img className="" src={directorpic} alt="" style={isMobile ? {width: "300px", height: "300px" } : {width: "150px", height: "150x"}} />
                    <div>
                        <p className="text-justify">Institute for Continuing Education was established by the Senate of Abia State Universit, Uturu to provide education and learning through open, distance and eLearning in the West African sub-region and beyond. Presently, it has study centres in the South East, with some affiliated Institutions.  </p>
                        <p className="text-justify">As foremost educational institution in Nigeria, Abia State University are noted for world class teachers and learning environment. The Institute has access to a pool of open and distance learning experts and experienced teachers to meet the specific training needs of our students. </p>
                    </div>
                </div>
                <p className="text-justify"> As the Director, I am proud to present ICE to the global academic community, and invite you to embrace our open, distance and eLearning of the Institute for Continuing Education of Abia State University, Uturu.  </p>
                <p className="text-justify" style={{fontWeight:"900"}}>
                    Professor Joshua U Ogbonna <br />							      
                    Professor of Geographic Information Systems and Spatial Modelling <br />
                </p>
            </div>
            {/* <div>
                <h3 style={{fontWeight:"800"}}>ABOUT ICE</h3>
                <p style={{fontSize:"18px", fontStyle:"italic", fontWeight:"800"}} className="text-center text-yellow">EXPANDING LEARNING POSSIBILITIES</p>
            </div> */}
            
            <div className="pt-4">
                <h1 style={{fontWeight:"800"}}>Mission of the Institute</h1>
                <p className="text-justify">To provide flexible, innovative and cost-effective system of higher education to group of learners who were unable to undergo higher education due to some reasons and cannot engage in the traditional face to face university learning mode of study.</p>
            </div>

            <div>
                <h1 style={{fontWeight:"800"}}>Our Vision</h1>
                <p className="text-justify">To be a Centre of Excellence in the provision of educational services through Electronic/Hybrid Learning mode.</p>
            </div>

            <div>
                <h1 style={{fontWeight:"800"}}>Our Philosophy</h1>
                <p className="text-justify">Our philosophy is to assist the Nation through this Institute to develop the minds of her citizens through the acquisition of university education. Thereby provide a veritable combination of infrastructure, training and management that is necessary for effective human capacity development of the Nigerian labour force.</p>
            </div>

            <div>
                <h1 style={{fontWeight:"800"}}>ABOUT THE INSTITUTE FOR CONTINUING EDUCATION</h1>
                <p className="text-justify">The Part-Time programme of Abia State University started in 1988 by the Act of University Senate as Institute for Distance Education Administration (IDEA) with multiple Centres at Uturu, Aba, Umuahia, Port Harcourt, Lagos, Uyo, Calabar, Onitsha, Owerri. Enugu, Orlu, and Warri. These centres were established to make university education available to many in response to the UNESCO and World Bank. The sandwich option of the Part Time programme was established in 1989 but domiciled in the Faculty of Education. These centres were closed down in response to the insistence that Universities should close down all Distance Learning Centres outside their region. This led to the closure of all ABSU centres leaving only 3 Centres at <br/></p>
                <p className="container">
                    •	 Uturu Centre domiciled at Abia State University Main Campus <br/>
                    •	Umuahia Centre at Abia State University Umuahia Location, Umudike <br/>
                    •	Aba Centre at ABSUTH (Abia State University Teaching Hospital) <br/>
                </p>
                <p>
                    Graduates of the Part Time programmes (IDEA and Sandwich) of Abia State University are in all works of life in Nigeria and in the diaspora, which was the basic aim of why it was established. 
                    In 2016 the Institute for Distance Education Administration (IDEA) changed nomenclature to Institute for Continuing Education (ICE) to rejig the dwindling fortunes of IDEA and also manage the Sandwich programme. 
                    New study centres were established in 2024
                    Ebonyi State: Uburu, Afikpo and Abakiliki Distance Learning Centres
                    Enugu State: Enugu Urban, Nkanu and Nsukka Distance Learning Centres
                    Abia State: Obehie Distance Learning Centre
                </p>
                <p>Affiliate Institutions:</p>
                <p className="container">
                    •	Peaceland College of Education, Enugu <br/>
                    •	Golden Lay Theological Institute, Arochukwu <br/>
                    •	Archbishop Charles Heerey Memorial College of Nursing Sciences, Aba, Abia State <br/>
                    •	College of Nursing Sciences, Amaigbo in Imo State <br/>
                    •	Covenant College of Education, Aba <br/>
                </p>
            </div>

            
        </div>

        <Footer />
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutICE);
