import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../redux/actions";
import { useMergeState } from "../../utils/helpers";
import ICEHeader from "../../layouts/ICEHeader";
import Footer from "../../layouts/Footer";
import { enquireScreen } from "enquire-js";
import directorpic from  "../../assets/images/absu_director.png";
import vcpic from  "../../assets/images/absu_vc.jpeg";
import senatepic from  "../../assets/images/absu_senate.jpg";
import { PulseSpinner } from "react-spinners-kit";
import $ from "jquery";


let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const ICE = (props) => {
  const [state, setState] = useMergeState({
    featured: [],
    attendants: [],
    shopSchedule: [],
    newsOpen: false,
    newsTitle: "",
    newsValue: "",
    myPosts: [],
  });


  useEffect(() => {
    
    setTimeout(() => {
      $("#preloader").fadeOut();
    }, 1000);
    }, []);

  require("antd/dist/antd.css");

  return (
    <>
    <div id="preloader">
        <div id="status">
          <center>
            <PulseSpinner
              color="white"
              backColor="#FFF"
              frontColor="#FFF"
              size={45}
            />
          </center>
        </div>
    </div>
      <div className="min-vh-100">
        <ICEHeader />
        <div style={{paddingTop:"6em"}}>
            <img className="" src={senatepic} alt="" style={isMobile ? {width: "100%", height: "150px" } : {width: "100%", height: "270px"}}/>
        </div>
        <div className="container pt-4 bg-sky px-4"> 
            <div>
                <h1 style={{fontWeight:"800"}}>INSTITUTE FOR CONTINUING EDUCATION (ICE)</h1>
                <p style={{fontSize:"18px", fontStyle:"italic", fontWeight:"800"}} className="text-center text-yellow">EXPANDING LEARNING POSSIBILITIES</p>
            </div>
        

            <div className="pt-4">
                <h3 style={{fontWeight:"800"}}>VICE CHANCELLOR’S WELCOME ADDRESS</h3>
                <div className="d-block d-lg-flex">
                    <img className="mr-3 rounded-5" src={vcpic} alt="" style={isMobile ? {width: "300px", height: "300px" } : {width: "300px", height: "300x"}} />
                    <div>
                        <p className="text-justify">On behalf of the governing authorities of the University, I welcome you to the Institute for Continuing Education (ICE), Abia State University (The God’s Own State University). The Institute for Continuing Education (ICE) was established by Abia State University Senate in 2016 as a way to rejig the dwindling fortunes of the Institute for Distance Education Administration (IDEA) that was established in 1988. It was established to run on part-time degree programmes of the university. It also coordinates the sandwich programmes of the Faculty of Education and also coordinates academic activities of the affiliate institutions. </p>
                        <p className="text-justify"> Abia State being an academic mobile and vibrant state with high level of entrepreneurial population, has a lot of people that requires work with studies. Abia State University being the foremost academic institution in the South East places a lot of emphasis on the activities of this Institute as it caters for the academic needs of this group (workers), especially in our urban areas where many of them exists.</p>
                    </div>
                </div>
                <p className="text-justify"> They will leverage on the high level of academic standard and excellence of their full-time counterpart for which Abia State University is associated with to excel. </p>
                <p className="text-justify">Currently, the University is embarking on services that will make part-time studies enjoyable and seamless at ICE. These include embracing e-Administration, ICE webpage to enhance the internationalization and visibility of the programmes, ICE Students Handbook available as reference material for students and prospective students, and lecturers.</p>
                <p className="text-justify"> I once again, welcome you to the Institute for Continuing Education and wish you fruitful and exciting memories. </p>
                <p className="text-justify"> God bless you. </p>
                <p className="text-justify" style={{fontWeight:"900"}}>
                    Professor Maxwell O. Ogbulu <br />							      
                    Vice Chancellor, <br />							                             
                    Abia State University, Uturu
                </p>
            </div>

            
        </div>

        <Footer />
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ICE);
