export const userType = {
    superadmin: 'Super Admin',
    schooladmin: 'School Admin',
    hod: 'Department Administrator',
    instructor: 'Instructor',
    student: 'Student',
}

export const PaymentCheck = {
    EnabledAndPaid: 1,
    EnabledAndNotPaid :2,
    Disabled: 2
    
}
export const FORGOT_PASSWORD = 'forgot_password'
