import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../redux/actions";
import { useMergeState } from "../../utils/helpers";
import FrontHeader from "../../layouts/FrontHeader";
import ICEHeader from "../../layouts/ICEHeader";
import Footer from "../../layouts/Footer";
import { enquireScreen } from "enquire-js";

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const Affiliation = (props) => {
  const [state, setState] = useMergeState({
    featured: [],
    attendants: [],
    shopSchedule: [],
    newsOpen: false,
    newsTitle: "",
    newsValue: "",
    myPosts: [],
  });


  

  require("antd/dist/antd.css");

  return (
    <>
      <div className="min-vh-100">
        <FrontHeader />

        <div className="container pt-8 bg-sky px-4"> 
            <div>
                <h1 style={{fontWeight:"800"}}>CONDITIONS FOR AFFILIATION WITH ABIA STATE UNIVERSITY, UTURU, ABIA STATE, NIGERIA</h1>
                <p className="text-justify">The following are the conditions for affiliation with Abia State University, Uturu: </p>
                <div className="container">
                    <div>
                        <h3 style={{fontWeight:"800"}}>1.	Institutions Eligible for Affiliation</h3>
                        <p className="text-justify">Abia State University may affiliate with the following categories of institutions:</p>
                        <p className="container text-justify">
                            •	Colleges of Education, <br/>
                            •	Polytechnics and Colleges of Technology, <br/>
                            •	Colleges of Management and Sciences, Nursing Sciences, Public Health, Environmental Studies <br/>
                            •	Monotechnics in areas relating to Agriculture, and <br/>
                            •	Other Institutions that are registered with the State or Federal government. <br/>
                        </p>
                    </div>
                    
                    <div>
                        <h3 style={{fontWeight:"800"}}>2.	Conditions for Affiliation</h3>
                        <p className="container text-justify">
                            •	The institutions seeking affiliation with Abia State University must be institutions that are fully approved by the State or Federal Government of Nigeria. <br/>
                            •	A detailed academic brief must be submitted for the programmes for which the institution is seeking affiliation. The brief must contain the curriculum, staff list, and available facilities. <br/>
                            •	The institution must submit an application with complete brief of the Institution. <br/>
                            •	The institution must have a Governing Council that has representation reflecting the public interest and must have a Chief Executive Officer that is responsible to the Council. <br/>
                            •	The institution must have for each of its programmes, clearly defined objectives appropriate to higher education in level, standards and quality, as well as the means for achieving them. <br/>
                            •	The institution must have students enrolled in the programmes for which affiliation is sought. <br/>
                            •	The institution must have academic and non-academic staff that are sufficient in number, qualifications and experience to support academically and administratively, the programmes for which affiliation is sought. <br/>
                            •	The institution must show that it has the financial resources to support the programmes for which affiliation is sought. <br/>
                            •	The institution must have adequate Library, Laboratory and Computer and other resources to support the programmes for which affiliation is sought. <br/>
                        </p>
                    </div>

                    <div>
                        <h3 style={{fontWeight:"800"}}>3.	Duration and Renewal of Affiliation</h3>
                        <p className="container text-justify">
                            •	Duration of affiliation shall be five (5) years subject to renewal. <br />
                            •	The process of renewal of affiliation shall commence one (1) year to the expiration of affiliation. <br />	
                        </p>
                    </div>

                    <div>
                        <h3 style={{fontWeight:"800"}}>4.	Submission of Application</h3>
                        <p className="text-justify">Application for affiliation can be written and submitted to the Vice Chancellor throughout the year. The application may be accompanied with a letter of intent stating clearly the vision of the institution concerning the affiliation.</p>
                    </div>

                    <div>
                        <h3 style={{fontWeight:"800"}}>5.	Follow-up Action on Submitted Applications</h3>
                        <p className="container text-justify">
                            •	On receipt of an application, the University Management will examine the documents submitted by the institution seeking affiliation. If it is observed that the institution fulfils the minimum conditions, an inspection committee will be appointed in order to visit and assess the suitability for affiliation with the university. <br />
                            •	Composition of the inspection Committee shall comprise the Director of The Institute for Continuing Education as Chairman, two senior academics (preferably Professors) as members from the concerned Faculty/Department. In the case of Postgraduate programme, the Director of the Institute, Dean of Postgraduate School and two members from the affected Faculty shall constitute the visitation team. In all, the Institute Secretary or any nominee of the Registrar shall act as the inspection secretary.  <br />
                            •	If during the scrutiny of the documents by the Inspection Committee, an institution is found to have failed to fulfil the minimum conditions, it will be informed accordingly and no further action shall be taken.  <br />
                            •	The report of the Inspection Committee shall be submitted to the Vice Chancellor for the University Management consideration and recommendation for further actions.  <br />
                            •	The Vice Chancellor will mandate the legal unit to draft the Memorandum of Understanding (MoU)  <br />
                            •	The draft of the MoU shall be sent to the affiliating Institutions for comments relative to the terms and contents of the MoU.  <br />
                            •	With the approval of the MoU by both parties, the Vice Chancellor convokes the signing of the MoU ceremony, chaired by the Vice Chancellor with members of Management, Director Institute for Continuing Education, and Director of Academic Planning as members of the University Team and Team members of the Affiliate Institutions.  <br />
                        </p>
                    </div>

                    <div>
                        <h3 style={{fontWeight:"800"}}>6.	Guidelines for Institutions and Inspection Committees</h3>
                        <p className="text-justify">The institution awaiting inspection for approval should make available the following information (records/documents) ready for the inspection committee</p>
                        <p className="container text-justify">
                            •	A copy of the law governing the institution <br />
                            •	Composition of the management of the institution <br />
                            •	List of staff with qualifications, ranks and salary structure <br />
                            •	Library facilities <br />
                            •	Laboratories/Workshops <br />
                            •	Number of students <br />
                            •	Academic brief for the programme for which affiliation is sought <br />
                            •	Record of assessment of students <br />
                            •	Any other relevant information <br />
                        </p>
                    </div>

                    <div>
                        <h3 style={{fontWeight:"800"}}>7.	Memorandum of Understanding</h3>
                        <p className="text-justify">There will be a memorandum of understanding duly signed by officers for the university and the affiliating institution.</p>
                    </div>

                    <div>
                        <h3 style={{fontWeight:"800"}}>8.	Shared Affiliation Responsibilities</h3>
                        <p className="container text-justify">
                            •	The University shall examine admission requirements to ensure that they are in conformity with that of Abia State University, Uturu. <br />
                            •	The Affiliated Institutions shall adopt the curriculum of Abia State University in the affiliated programmes to ensure that they are of the same standard. <br />
                            •	Both parties shall moderate all examinations and assessments to ensure that the conditions and requirements are in accordance with the University Academic Laws. <br />
                            •	Each affiliated institution must develop long-term plans for its academic mission, role and capacity, which build on the strengths and special environment of the institution and submit it to the University through the Institute for Continuing Education. <br />
                            •	The Director, Institute for Continuing Education shall direct the relevant Department to nominate an external examiners not below the rank of Professor from recognized institution (including Abia State University) which will be sent to the Vice-Chancellor for approval. <br />
                        </p>
                    </div>

                    <div>
                        <h3 style={{fontWeight:"800"}}>9.	Management of Affiliated Programmes</h3>
                        <p className="container text-justify">
                            •	Both parties shall consider and approve the list of staff involved in teaching of courses in the affiliated programme at the beginning of each session. <br />
                            •	Both parties shall consider and approve the list of candidates for admission into the affiliated programme <br />
                            •	Admission forms shall be purchased from the affiliated institution and/or Abia State University website. <br />
                            •	There shall be periodic visits from the University to carry out inspection of facilities in the affiliated institution. <br />
                            •	The university shall make arrangement for students of the affiliated institution who may wish to conduct their research in Abia State University and shall be at the expense of the affiliated institution. <br />
                            •	The Director of the Institute for Continuing Education shall give regular reports to the Vice Chancellor on affiliated Institutions and Distance Education Centre’s on the academic activities of the programmes. The Director of the Institute with the relevant Departments shall be responsible for the moderation of examination questions and shall be the University contact person for the affiliated institution and shall be represent the affiliated Institutions in University Senate; and shall represent the University in the Affiliated Institutions Governing Council. <br />
                            •	The examination results of students in the affiliated programmes shall be prepared by the affiliated institution six weeks after the examinations. After the results have been considered and approved at all levels at the affiliated institution, the result shall be submitted to the Director of the Institute for Continuing Education for further processing. <br />
                            •	The Institute for Continuing Education shall ensure that their Senate approved results are returned to the affiliated institution. <br />
                        </p>
                    </div>

                    <div>
                        <h3 style={{fontWeight:"800"}}>10.	Procedure for the Consideration of students’ results shall be:</h3>
                        <p className="container text-justify">
                            •	Undergraduate Degree programme:
                            <p className="container text-justify">Director, ICE → ICE Academic Board→ Senate</p>
                        </p>
                        <p className="container text-justify">
                            •	Postgraduate Programme:
                            <p className="container text-justify">Director, ICE → ICE Academic Board →Senate.</p>
                        </p>
                    </div>

                    <div>
                        <h3 style={{fontWeight:"800"}}>11.	Payment of Fees</h3>
                        <p className="container text-justify">
                            •	With the adoption of the E-Administration by Abia State University, 30% of the proceeds from the sale of admission shall go to Abia State University, while 70% will go to the affiliated institution. <br />
                            •	30% of tuition fees from affiliated programmes shall be paid to Abia State University <br />
                            •	The payments above by students shall be made at the Abia State University payment platform which automatically distributes the money along the approved sharing formula with Affiliate Institutions (currently approved by University Management). <br />
                            •	Abia State University shall withhold the release of results of students if the University is being owed by the Affiliate Institution. <br />
                        </p>
                    </div>

                    <div>
                        <h3 style={{fontWeight:"800"}}>12.	Withdrawal of Affiliation</h3>
                        <p className="container text-justify">
                            •	The University has the right to withdraw the affiliation of an institution or some of the affiliated programmes in the institution. <br />
                            •	Proceedings of withdrawal of affiliation may be initiated by the University in case an institution is found guilty of the following: <br />
                            <p className="container text-justify">
                                * Engagement in activities prejudicial to the interest of the University or inciting feelings of disloyalty or disaffection against the University. <br />
                                * Non-fulfilment of conditions laid down regarding deficiencies to be addressed in spite of written notices. <br />
                                * Disregard of rules and conditions of affiliation even after receiving written warnings. <br />
                                * Non-availability of proper equipment/space/staff for teaching a particular programme. <br />
                                * Compromise of academic standards by the institution. <br />
                                * Failed accreditation of the programme by such regulatory bodies like National Board for Technical Education (NBTE) and NCCE. <br />
                                * Delay in processing and release of results in spite of written notices. <br />
                            </p>
                            •	The University shall provide adequate time and opportunity to the management of the school or institution served with the notice of de-affiliation, up to a maximum of one year for adequate removal/compliance of defects failing which the University may declare the institution de-affiliated. <br />
                            •	The affiliated institution may decline to renew affiliation after five (5) years. <br />
                            •	An affiliated institution wishing to terminate affiliation before the end of five years duration will have to give one (1) year notice before termination. <br />
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Affiliation);
