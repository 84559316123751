import React,{useEffect} from "react";
import Header from "../../layouts/FrontHeader";
import Footer from "../../layouts/Footer";
import Marquee from "react-fast-marquee";
import { enquireScreen } from "enquire-js";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Senate from "../../assets/images/senate.jpeg";
import { Input, Modal, Upload, message, Image } from 'antd';
import Walkway from "../../assets/images/walk_way-640x301.jpeg";
import Endpoint from "../../utils/endpoint";
import Absu_Logo from "../../assets/images/Abia_State_University_logo.svg.png";
import senateone from "../../assets/images/absu_senate.jpg";
import senatetwo from "../../assets/images/senate.jpeg";
import welcomepic from "../../assets/images/absu_welcome.jpeg";
import absugate from "../../assets/images/absu_gate.jpeg";
import studentcaro from "../../assets/images/absu_students_caro.jpeg";
import mountaincaro from "../../assets/images/absu_mountain_caro.jpg";
import vccaro from "../../assets/images/absu_vc_caro.webp";


let isMobile;


enquireScreen((b) => {
    isMobile = b;
});
const IndexPage = () => {

    const fetchPost = () => {
        // Endpoint.getPosts()
        Endpoint.getWpCategories()
        .then((res) => {
            console.log(res)
        })
        .catch((err) => {
            console.log(err)
        })
    }


    useEffect(() => {
        fetchPost();
    }, []);
  //   require("../../../src/assets/css3/vendor/vendor.min.css");
  return (
    <>

      <Header />
      <div id="page" className="" style={{marginTop:"6em"}}>
        {/* Header Section Start */}

        {/* Header Section End */}
        {/* Slider/Intro Section Start */}

        {/* Slider/Intro Section End */}
        {/* Feature Section Start */}

        <div className="container-fluid shadow" style={{width:"100%", marginTop:"8em"}}>
            {/* <img src="https://abiastateuniversity.edu.ng/wp-content/uploads/2017/08/E-LIBRARY-IN-UTURU-LOCATION-891x420.jpg"/> */}

          <div className="row hero_new">
            <div className="col-sm-12">
            <Carousel autoPlay infiniteLoop>
                <div>
                    <img style={{width:"100%", height:"100%"}} src={vccaro} />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
             
                <div>
                    <img style={{width:"100%", height:"100%"}} src={studentcaro}/>
                </div>
                <div>
                    <img style={{width:"100%", height:"100%"}} src={absugate}/>
                </div>
            
            </Carousel>
            </div>
            {/* <div className="col-sm-3 text-center" style={!isMobile ? {paddingTop:"10em", background:"#929292", height:"58vh", color:"#fff"} : {background:"#251347", color:"#fff", padding:"20px"}}>
                <h3 className="text-white">ABIA STATE UNIVERISTY</h3>
                <p className="text-white text-center mt-3">As a foremost state University in Nigeria founded in 1981, Abia State University has maintained its leadership within and beyond the Eastern heartlands of Nigeria</p>
            </div> */}

          </div>
        </div>
        <div className="section wep" style={{background:"#f7f8fb", padding:"20px", color:"#fff", height:"30em"}}>
          <div className="container row">
            <div className="col-sm-12 col-lg-6">
              <h3 style={{fontSize:"23px", fontWeight:"700"}}>News</h3>
              <img src={Absu_Logo} style={{opacity:"0.5", marginTop:"-48px"}}/>
            </div>
            {/* Section Title Start */}

            <div className="col-sm-12 col-lg-6 text-black" data-aos="fade-up">
              <h2 className="title maitree text-black" style={!isMobile ? {fontSize:"26px", color:"#000"} : {fontSize:"18px", color:"#fff"}}>
              We prepare you for the future
              </h2>
              <p className="text-black mt-3" style={{color:"#000"}}>
            At Abia State University, we believe in education that empowers individuals towards excellence and service.
 It has remained resilient in the acquisition and dissemination of ICT-driven skills, comparable to graduates anywhere in Africa.
            </p>
            </div>
            {/* Section Title End */}
            {/* Feature Wrapper Start */}
            
            {/* Feature Wrapper End */}
            
          </div>
        </div>
        {/* <div style={{width:"100%", height:"30px", background:"#0e0e6f", paddingTop:"10px"}}>
    
    <Marquee gradient={false} speed={40} style={{ zIndex: "9" }}>
        {newsArr && newsArr.map((x) => {
            return (
                <p style={{ fontSize: "9px", color: "#fff" }} className="">
                    {x}
                    <i className="fa fa-star" /> &nbsp; &nbsp; &nbsp;
                </p>
            )
        })}
    </Marquee>
</div> */}
        {/* <div className="section" style={isMobile ? {marginTop:"2em"} : {marginTop:"11em"}}>
          <div className="container">
            <div className="section-title-two text-center" data-aos="fade-up">
              <h2 className="title maitree" style={!isMobile ? {fontSize:"22px"} : {fontSize:"18px"}}>
              At Abia State University, we believe in education that empowers individuals towards excellence and service.
 It has remained resilient in the acquisition and dissemination of ICT-driven skills, comparable to graduates anywhere in Africa.
              </h2>
            </div>
           
            
          </div>
        </div> */}



        <div className="section" style={{marginTop:"9em", background:"#09093f", padding:"20px", color:"#fff"}}>
          <div className="container">
            {/* Section Title Start */}
            <div className="section-title-two text-center" data-aos="fade-up">
              <h2 className="title maitree" style={!isMobile ? {fontSize:"26px", color:"#fff"} : {fontSize:"18px", color:"#fff"}}>
                        Campus Life
              </h2>
            </div>
            {/* Section Title End */}
            {/* Feature Wrapper Start */}
            <div className="row row-cols-xl-3 row-cols-md-2 row-cols-1 max-mb-n60">
              {/* Feature Start (Icon Box) */}
              <div className="col max-mb-60" data-aos="fade-up">
                <div className="icon-box-list" data-vivus-hover>
                  <div className="icon">
                    <img
                      className="svgInject"
                      src="assets/images/svg/linea/linea-basic-lightbulb.svg"
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <h3 className="title"></h3>
                    <div className="desc">
                    <Image
                        width={200}
                        src={Senate}
                    />
                    </div>
                  </div>
                </div>
              </div>
              {/* Feature Start (Icon Box) */}
              {/* Feature Start (Icon Box) */}
              <div className="col max-mb-60" data-aos="fade-up">
                <div className="icon-box-list" data-vivus-hover>
                  <div className="icon">
                    <img
                      className="svgInject"
                      src="assets/images/svg/linea/linea-basic-map.svg"
                      alt=""
                    />
                  </div>
                  <div className="content">
                    {/* <h3 className="title">Anywhere</h3> */}
                    <div className="desc">
                    <Image
                        width={200}
                        src={Senate}
                    />
                    </div>
                  </div>
                </div>
              </div>
              {/* Feature Start (Icon Box) */}
              {/* Feature Start (Icon Box) */}
              <div className="col max-mb-60" data-aos="fade-up">
                <div className="icon-box-list" data-vivus-hover>
                  <div className="icon">
                    <img
                      className="svgInject"
                      src="assets/images/svg/linea/linea-basic-clockwise.svg"
                      alt=""
                    />
                  </div>
                  <div className="content">
                    {/* <h3 className="title">On Time</h3> */}
                    <div className="desc">
                    <Image
                        width={200}
                        src={Senate}
                    />
                    </div>
                   
                  </div>
                </div>
              </div>
              {/* Feature Start (Icon Box) */}
              {/* Feature Start (Icon Box) */}
              <div className="col max-mb-60" data-aos="fade-up">
                <div className="icon-box-list" data-vivus-hover>
                  <div className="icon">
                    <img
                      className="svgInject"
                      src="assets/images/svg/linea/linea-basic-laptop.svg"
                      alt=""
                    />
                  </div>
                  <div className="content">
                    {/* <h3 className="title">Online Coures</h3> */}
                    <div className="desc">
                    <Image
                        width={200}
                        src={Senate}
                    />
                    </div>
                  </div>
                </div>
              </div>
              {/* Feature Start (Icon Box) */}
              {/* Feature Start (Icon Box) */}
              {/* <div className="col max-mb-60" data-aos="fade-up">
                <div className="icon-box-list" data-vivus-hover>
                  <div className="icon">
                    <img
                      className="svgInject"
                      src="assets/images/svg/linea/linea-basic-life-buoy.svg"
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <div className="desc">
                    <Image
                        width={200}
                        src={Senate}
                    />
                    </div>
                  </div>
                </div>
              </div> */}
              {/* Feature Start (Icon Box) */}
              {/* Feature Start (Icon Box) */}
             
              {/* Feature Start (Icon Box) */}
            </div>
            {/* Feature Wrapper End */}
            
          </div>
        </div>
        {/* Feature Section End */}
        {/* Service Section Start */}
     
        {/* Service Section End */}
        {/* Course Section Start */}
       
        {/* Course Section End */}
        {/* Faq Section Start */}
      
        {/* Faq Section End */}
        {/* Event Section Start */}
     
        {/* Event Section End */}
        {/* Testimonial Section Start */}
      
        {/* Testimonial Section End */}
        {/* Newsletter Section Start */}
        
        {/* Newsletter Section End */}
        <Footer />
        {/* Scroll Top Start */}
       
        {/* Scroll Top End */}
      </div>
    </>
  );
};

export default IndexPage;
