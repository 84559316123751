import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../redux/actions";
import Logo from "../assets/images/Abia_State_University_logo.svg.png";
import TikTok2 from "../assets/images/kkk.jpeg";
import { enquireScreen } from "enquire-js";
import { useMergeState } from "../utils/helpers";

import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

const content = (
  <div>
    <Link to={"/aboutuche"} style={{ color: "#000", fontSize: "13px" }}>
      <p style={{ color: "#000", fontSize: "10px" }}>&nbsp;</p>
    </Link>
    <Link to={"/profiles_"} style={{ color: "#000", fontSize: "13px" }}>
      <p style={{ color: "#000", fontSize: "12px" }}>
        {" "}
        <p style={{ color: "#000", fontSize: "12px" }}>Profile</p>
      </p>
    </Link>

  </div>
);
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const ICEHeader = (props) => {
  const [state, setState] = useMergeState({
    featured: [],
    attendants: [],
    shopSchedule: [],
    newsOpen: false,
    newsTitle: "",
    newsValue: "",
    myPosts: [],
    aboutMenu: false,
  });
  const openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
  };

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };
  const [clear, setClear] = useState(false);

  const changeClear = () => setClear((prev) => !prev);

  const toggleAbout = () => {
    setState({
      aboutMenu: !state.aboutMenu,
    });
  };
  require("antd/dist/antd.css");

  return (
    <>
      <Navbar
        className="navbar-top shadow 
        "
        expand="md"
        style={{
            position: "fixed",
          zIndex: "99999",
          background: "#08248c",
          width: "100%",
          height: "85px",
          marginTop:"0px"
        }}
      >
        <div className="" style={isMobile ? {display:"contents"} : {display:"contents"}}>
          {props.logoOut == null ? (
            <NavbarBrand to="/" tag={Link}>
              <img src={Logo} alt="" style={isMobile ? { width: "2.5em" } : { width: "3.5em", marginLeft:"3em" }} /> &nbsp;
             
              {/* <span> e-Learn NG</span> */}
            </NavbarBrand>
          ) : null}

          {/* <button className="navbar-toggler" id="navbar-collapse-main" style={{color:"#fff"}}> */}
          {/* <span className="navbar-toggler-icon" style={{color:"#fff"}}/> */}
          {isMobile ? (
            <i
              onClick={openNav}
              className="fa fa-bars"
              id="navbar-collapse-main"
              style={{ color: "#fff", fontSize: "29px", marginRight: "10px"}}
            />
          ) : null}
          {/* </button> */}

          {!isMobile ? (
            <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="8">
                    <Link to="/">
                      <img src={Logo} alt="" style={{ height: 50 }} />
                      {/* <span> Uche Ndi Abia</span> */}
                    </Link>
                  </Col>

                  <Col className="collapse-close" xs="4">
                    <button
                      className="navbar-toggler"
                      id="navbar-collapse-main"
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <h2 style={{fontSize:"14px", fontWeight:"600"}} className="text-uppercase maitree text-white"> Abia State<br/>University</h2>
              <Nav className="ml-auto" navbar>
                <NavItem className="ml-3" style={{ display: "flex" }}>
                  <NavLink className="nav-link-icon" to="/" tag={Link}>
                    <span className="nav-link-inner--text">Home</span>
                  </NavLink>
                  <NavLink className="nav-link-icon" to="/ICE" tag={Link}>
                  
                    <span className="nav-link-inner--text">ICE</span>
                    
                  </NavLink>
                  <NavLink className="nav-link-icon" to="/aboutICE" tag={Link}>
                  
                    <span className="nav-link-inner--text">About ICE</span>
                    
                  </NavLink>

                  <NavLink className="nav-link-icon" to="/ICE" tag={Link}>
                    <span className="nav-link-inner--text">Academics <i className="fa fa-angle-down"/></span>
                  </NavLink>
                
                  <NavLink 
                    className="nav-link-icon" 
                    to="/ICE" 
                    tag={Link}
                  >
                    {/* <i className='fa fa-arrow-right'/> */}
                    <span className="nav-link-inner--text">Admissions <i className="fa fa-angle-down"/></span>
                  </NavLink>
             
                  <NavLink
                    className="nav-link-icon"
                    to="/ICE"
                    tag={Link}
                  >
                    <span className="nav-link-inner--text">Payment</span>
                  </NavLink>
                  <NavLink
                    className="nav-link-icon"
                    to="/ICE"
                    tag={Link}
                  >
                    <span className="nav-link-inner--text">Support Centre</span>
                  </NavLink>
                  <NavLink
                    className="nav-link-icon"
                    to="/ICE"
                    tag={Link}
                  >
                    <span className="nav-link-inner--text">Library</span>
                  </NavLink>

               
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          ) : null}
          {/* <button type="button" onClick={openNav} className="menu-toggle" style={{zIndex:"99999", color:'#000', position:"fixed"}}><i className="fa fa-bars"  style={{font:"15px"}}/></button> */}
          <div id="mySidenav" class="sidenav1" style={{ zIndex: "99999999" }}>
            <a href="#" class="closebtn" onClick={closeNav}>
              &times;
            </a>
            <Link to="/">Home</Link>
            <Link to={"/ICE"}>ICE</Link>
            <Link to={"/aboutICE"}>About ICE</Link>
            <Link to="/ICE">Academics <i className="fa fa-angle-down"/></Link>
            <Link to="/ICE">Admissions <i className="fa fa-angle-down"/></Link>
            <Link to="/ICE">Payment</Link>
            <Link to="/ICE">Support Centre</Link>
            {/* <a href={Manifesto} target="_blank">Manifesto</a> */}
            <Link to="/ICE">Library</Link>
            {/* <Link to="/staff_authentication">Staff Form</Link> */}

            <br />
            <br />

            {/* <p>
              <span className="text-white">
                <a
                  href="https://web.facebook.com/EUcheIkonne"
                  className="text-white"
                  target={"_blank"}
                >
                  <i
                    className="fa fa-facebook-official"
                    style={{ fontSize: "28px", marginRight: "25px" }}
                  />
                </a>
              </span>

              <span className="text-white">
                <a
                  href="https://twitter.com/ikonne_uche?t=3_aLzAbeB-U9ypSsp2e5Lg&s=09"
                  className="text-white"
                  target={"_blank"}
                >
                  <i
                    className="fa fa-twitter-square"
                    style={{ fontSize: "28px", marginRight: "25px" }}
                  />
                </a>
              </span>

              <span className="text-white">
                <a
                  href="https://instagram.com/uchendiabia2023?igshid=YmMyMTA2M2Y="
                  className="text-white"
                  target={"_blank"}
                >
                  <i
                    className="fa fa-instagram"
                    style={{ fontSize: "28px", marginRight: "25px" }}
                  />
                </a>
              </span>

              <span className="text-white">
                <a
                  href="https://vm.tiktok.com/ZMNBaosmA/"
                  className="text-white"
                  target={"_blank"}
                >
                  <img src={TikTok2} style={{ width: "30px" }} />
                </a>
              </span>
            </p> */}
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ICEHeader);
